@use '@angular/material' as mat;
@import "variables";

html.cdk-global-scrollblock {
  overflow-y: hidden;
}

.cdk-overlay-container .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.2);
}

.mat-mdc-dialog-surface {
  border: 1px solid var(--color-outline-variant);
}

.mat-mdc-dialog-panel {
  --mat-dialog-container-max-width: 100%;
  --mat-dialog-container-min-width: 430px;
  --mat-dialog-actions-alignment: center;

  &.full-screen {
    height: calc(100vh - 32px);
    width:  calc(100vw - 32px);
    max-width: 100vw;
  }

}

.allow-impersonation-popup {
  .inline-code {
    background-color: var(--color-surface-container-high);
    border: 1px solid var(--color-outline);
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    font-family: $font-family-monospace;
  }
}

@mixin dialogs($theme) {
  @if mat.get-theme-type($theme) == light {
    --mdc-dialog-container-color: var(--color-surface-container-lowest);
  } @else {
    --mdc-dialog-container-color: var(--color-surface-container-low);
  }
}

