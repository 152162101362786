:root {

  .mat-mdc-menu-panel {
    --mat-app-elevation-shadow-level-2: none;
  }

  .mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-size: 11px;
    --mdc-plain-tooltip-supporting-text-line-height: 14px;

    &.break-line {
      .mdc-tooltip__surface {
        white-space: pre-line;
      }
    }

    &.validation {
      .mdc-tooltip__surface {
        --mdc-plain-tooltip-container-color: var(--color-error);
      }
    }

    &.parameter-tooltip {
      margin: 6px auto 6px -140px;
    }
  }

  .mat-mdc-tooltip-surface {
    min-height: 22px;
    max-width: 400px;
  }

  .multiline-tooltip {
    background-color: var(--mdc-plain-tooltip-container-color, var(--mat-app-inverse-surface));
    color: var(--mdc-plain-tooltip-supporting-text-color, var(--mat-app-inverse-on-surface));
    border-radius: var(--mdc-plain-tooltip-container-shape, var(--mat-app-corner-extra-small));
    font-family: var(--mdc-plain-tooltip-supporting-text-font, var(--mat-app-body-small-font)), system-ui;
    font-size: var(--mdc-plain-tooltip-supporting-text-size, var(--mat-app-body-small-size));
    font-weight: var(--mdc-plain-tooltip-supporting-text-weight, var(--mat-app-body-small-weight));
    line-height: var(--mdc-plain-tooltip-supporting-text-line-height, var(--mat-app-body-small-line-height));
    letter-spacing: var(--mdc-plain-tooltip-supporting-text-tracking, var(--mat-app-body-small-tracking));

    a {
      color: var(--mdc-plain-tooltip-supporting-text-color, var(--mat-app-inverse-on-surface));

      &:hover {
        color: var(--color-surface-container-lowest);
        font-weight: 500;
      }
    }
  }

  .multiline-tooltip {
    white-space: pre-line;

    > div {
      text-align: left;
    }
  }
}
